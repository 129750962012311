import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { seo, intro, main } from '../content/logistyka'
import { video } from '../content/logistyka-kontraktowa'
import Layout from '../components/Layout'
import Intro from '../components/Intro'
import { useLangContext } from '../context/lang.context'
import { useFormContext } from '../context/form.context'
import Seo from '../components/Seo'
import Main from '../components/Main'
import Tiles from '../components/Tiles'
import Video from '../components/Video'
import ButtonForm from '../components/ButtonForm'
import LogisticsAdditionalText from '../sections/logistyka/LogisticsAdditionalText'

const Logistyka = () => {
  const { lang } = useLangContext()
  const { setFormOpen } = useFormContext()
  const { image, children, imageEcommerce, imageContract } =
    useStaticQuery(graphql`
      {
        image: file(absolutePath: { regex: "/images/intros/logistyka.jpg/" }) {
          publicURL
          childImageSharp {
            gatsbyImageData(
              quality: 100
              placeholder: DOMINANT_COLOR
              formats: [JPG]
            )
          }
        }
        children: allContentfulLogistyka(sort: { fields: name }) {
          nodes {
            name
            slug
            node_locale
            image {
              gatsbyImageData(width: 480, quality: 100, formats: [JPG])
            }
          }
        }
        imageEcommerce: file(
          absolutePath: { regex: "/images/intros/ecommerce.jpg/" }
        ) {
          childImageSharp {
            gatsbyImageData(
              quality: 100
              placeholder: DOMINANT_COLOR
              formats: [JPG]
            )
          }
        }
        imageContract: file(
          absolutePath: { regex: "/images/intros/logistyka-kontraktowa.jpg/" }
        ) {
          childImageSharp {
            gatsbyImageData(
              quality: 100
              placeholder: DOMINANT_COLOR
              formats: [JPG]
            )
          }
        }
      }
    `)

  const links = children.nodes
    .filter((node) => node.node_locale === 'pl')
    .map((node) => ({
      slug: node.slug,
      name: node.name,
      image: node.image,
    }))

  const linksMerged = [
    {
      slug: 'ecommerce',
      name: 'E-commerce',
      image: imageEcommerce,
    },
    {
      slug: 'logistyka-kontraktowa',
      name: 'Logistyka Kontraktowa',
      image: imageContract,
    },
  ].concat(links)

  const buttonBack = {
    text: {
      pl: 'Zapytaj o ofertę',
      en: 'Ask for an offer',
    },
    action: () => setFormOpen(true),
  }

  return (
    <Layout>
      <Seo
        title={seo.title[lang]}
        desc={seo.desc[lang]}
        image={'images/logistyka.jpg'}
        url={seo.url}
      />
      <Intro data={intro} image={image.childImageSharp} h={1} />
      <Main data={main} h={2} />
      <Tiles data={linksMerged} />
      <Video data={video} />
      <ButtonForm data={buttonBack} />
      <LogisticsAdditionalText /> 
    </Layout>
  )
}

export default Logistyka
