import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import AdditionalText from '../../components/AdditionalText'

const LogisticsAdditionalText = () => {

  const query = useStaticQuery(graphql`
    query {
      contentfulDodatkowyTekst(slug: {eq: "logistyka"}) {
        text {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  `)

  const text = query.contentfulDodatkowyTekst?.text.childMarkdownRemark.html;

  return (
    <AdditionalText text={text} />
  )
}

export default LogisticsAdditionalText